import i18n from 'shared/config/i18n'

export default defineI18nConfig(() => ({
  fallbackLocale: i18n.locales.at(0)?.code,
  numberFormats: i18n.locales.reduce((acc, locale) => {
    return {
      ...acc,
      [locale.code]: {
        currency: {
          style: 'currency',
          currency: locale.currency,
          currencyDisplay: 'symbol'
        }
      }
    }
  }, {}),
  fallbackWarn: false,
  missingWarn: false
}))
